<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_copyright">
      <div class="container">
        <div class="inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <p class="info-paragraph">
                Inquiries: 
                <span ref="contactSpan"></span>
            </p>
            <p class="info-paragraph">
                Developed by
                <a href="https://xphere.org/" target="_blank"
                  >Xphere LLC</a
                >
                &copy; {{ new Date().getFullYear() }}
            </p>
            <p class="info-paragraph">
              New King James Version®, Copyright© 1982, Thomas Nelson. All rights reserved.
            </p>
            <p class="info-paragraph">
              <a href="/terms-of-use" target="_blank">Terms of Use</a>
            </p>
            <p class="info-paragraph">
              <a href="/privacy-policy" target="_blank">Privacy Policy</a>
            </p>
          </div>
          <!-- <div class="right wow fadeInRight" data-wow-duration="1s">
            <ul>
              <li><a href="https://app.mypbe.com/terms-of-use" target="_blank">Terms &amp; Condition</a></li>
              <li><a href="https://app.mypbe.com/privacy-policy" target="_blank">Privacy Policy</a></li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CopyrightComponent",
  components: {},
  mounted() {
    this.$refs.contactSpan.innerHTML = `
      <a href='mailto:info@mypbe.com'>info@mypbe.com</a>
      `;
  }
};
</script>

<style scoped>
  .info-paragraph {
    font-size: small !important;
    margin-bottom: -10px;
  }
</style>