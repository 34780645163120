<template>
  <div class="dizme_tm_section" id="home">
    <div class="dizme_tm_hero">
      <div
        class="background"
        :data-img-url="`/img/slider/${dark ? 2 : 1}.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="hello">
              <h3 class="orangeText">Welcome to</h3>
            </div>
            <div class="name">
              <h3>MyPBE</h3>
            </div>
            <div class="job">
              <p>
                <span class="greenText">Supercharging</span> Your
                <span class="purpleText">PBE</span> Preparation
              </p>
            </div>
            <div class="button">
              <div class="video-button">
                <a class="anchor" @click="openVideoPopup()">
                  <span>
                    What's MyPBE? <img src="/img/slider/play.png" width="25px;" alt="play" />
                  </span>
              </a>
                <VideoPopup :show="isVideoPopupVisible" :videoUrl="videoUrl" @close="isVideoPopupVisible = false" />
              </div>
            </div>
            <div class="button" style="margin-top:20px;">
                <div class="dizme_tm_button mobile-only">
                  <a class="anchor" href="https://app.mypbe.com/login"><span>Login</span></a>
                </div>
                <div class="dizme_tm_button mobile-only" style="margin-left:50px;">
                  <a class="anchor" href="https://app.mypbe.com/register"><span>Get Started</span></a>
                </div>
              <!-- <div class="social">
                <ul>
                  <li>
                    <a href="#"><i class="icon-facebook-1"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-twitter-1"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-linkedin-1"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="icon-behance"></i></a>
                  </li>
                </ul>
              </div> -->
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img :src="`/img/slider/mypbe-avatar-${imgIndex}.webp`" alt="" />
              <!-- <span
                class="skills illustrator anim_moveBottom"
                v-html="ai"
              ></span>
              <span class="skills photoshop anim_moveBottom" v-html="ps"></span>
              <span class="skills figma anim_moveBottom" v-html="figma"></span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down non-mobile-only">
        <a class="anchor" href="#process" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ai, figma, mouse, mouseDark, ps } from "../svg";
import VideoPopup from "./popup/VideoPopup.vue";

export default {
  name: "HomeComponent",
  data() {
    return {
      ai,
      ps,
      figma,
      mouse,
      mouseDark,
      imgIndex: Math.floor(Math.random() * 3) + 1,
      isVideoPopupVisible: false,
      videoUrl: "https://www.youtube.com/embed/x1AQ_1oa2ZQ?si=bfEviaiiXJSqDLNU",
    };
  },
  methods: {
    openVideoPopup() {
      this.isVideoPopupVisible = true;
    },
    closeVideoPopup() {
      this.isVideoPopupVisible = false;
    },
  },
  props: {
    dark: { type: Boolean },
  },
  components: {
    VideoPopup
  },
};
</script>

<style scoped>

.dizme_tm_button a{
  padding: 10px 30px 10px 30px;
}

.video-button a{
	text-decoration: none;
	color: #fff;
	display: inline-block;
	font-family: 'Jost', sans-serif;
	font-weight: 500;
	border-radius: 50px;
	border: 2px solid #1cbe59;
	padding: 7px 10px 7px 10px;
	position: relative;
	overflow: hidden;
}

.video-button span{
	position: relative;
	z-index: 2;
	
	-webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.video-button a:before{
	position: absolute;
	content: "";
	z-index: 0;
	background-color: #1cbe59;
	left: -5px;
	right: -5px;
	bottom: -5px;
	height: 110%;
	
	-webkit-transition: all .3s ease;
  -moz-transition: all .3s ease;
  -ms-transition: all .3s ease;
  -o-transition: all .3s ease;
  transition: all .3s ease;
}
.video-button a:hover:before{
	height: 0;
}
.video-button a:hover span{
	color: #000;
}

.mobile-only {
  display: none;
}

.non-mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .mobile-only {
    display: block;
  }
}

@media (min-width: 768px) {
  .non-mobile-only {
    display: block;
  }
}
</style>