<template>
  <div class="root">
    <el-scrollbar>
      <el-card>
        <h1>Terms of Use</h1>

        <p>By accessing or using the MyPBE website (the "Site"), you agree to be bound by these Terms of Use. If you do not
          agree to these terms, please do not use the Site.</p>

        <h2>Use of the Site</h2>

        <ul>
          <li>You must be at least 10 years old to use the Site. Users under 13 must have parental or legal guardian consent
            to use the Site.</li>
          <li>You are responsible for maintaining the confidentiality of your account and password (if applicable) and for restricting
            access to your account.</li>
          <li>You agree to comply with all applicable laws and regulations when using the Site.</li>
        </ul>

        <h2>Prohibited Activities</h2>

        <ul>
          <li>You may not use the Site for any illegal or unauthorized purpose.</li>
          <li>You may not engage in any activity that interferes with or disrupts the Site or its servers.</li>
        </ul>

        <h2>Intellectual Property</h2>

        <p>The Site and its original content, features, and functionality are owned by Xphere LLC (MD) and are protected by
          international copyright, trademark, patent, trade secret, and other intellectual property laws.</p>

        <h2>Disclaimer of Warranties</h2>

        <p>The Site is provided on an "as is" and "as available" basis without any warranties of any kind.</p>

        <h2>Limitation of Liability</h2>

        <p>Xphere LLC (MD) and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any
          indirect, incidental, special, consequential, or punitive damages.</p>

        <h2>Governing Law</h2>

        <p>
          These Terms of Use shall be governed by and construed in accordance with the laws of the state of Maryland.
          Any dispute arising out of or related to these Terms will be subject to the exclusive jurisdiction of the courts located in Prince George's County, Maryland, regardless of the user's location or jurisdiction.
        </p>

        <h2>Changes to Terms</h2>

        <p>We may revise these Terms of Use from time to time. Any changes will be effective immediately upon posting on
          this page.</p>

        <h2>Contact Us</h2>

        <p>If you have any questions or concerns about these Terms of Use, please contact us at
            <span ref="contactSpan"></span>.</p>
      </el-card>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "TermsOfUse",
  mounted() {
    this.$refs.contactSpan.innerHTML = `
      <a href='mailto:info@mypbe.com'>info@mypbe.com</a>
      `;
  }
};
</script>
<style scoped>
.root {
  margin: 30px;
}
</style>