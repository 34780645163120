<template>
  <div class="dizme_tm_section" id="about">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/main.jpg`" alt="" />
              <!-- <div class="numbers year">
                <div class="wrapper">
                  <h3>
                    <VueJsCounter end="18"></VueJsCounter>
                  </h3>
                  <span class="name">Years of<br />Success</span>
                </div>
              </div> -->
              <!-- <div class="numbers project">
                <div class="wrapper">
                  <h3 style="display: flex">
                    <VueJsCounter as="span" end="8"></VueJsCounter>K+
                  </h3>
                  <span class="name">Total<br />Questions</span>
                </div>
              </div> -->
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="1s">
              <span>PBE Preparation, Supercharged</span>
              <!-- <h3>Everyone can be a PBE Master</h3> -->
            </div>
            <div class="text wow fadeInUp" data-wow-duration="1s">
              <p>
                <!-- Welcome to MyPBE, your go-to resource for excelling in the Pathfinder Bible Experience! Enhance your preparation with our interactive tools that make learning both effective and enjoyable. Track your progress with Readiness Scores, challenge yourself with Chapter Drills and Lightning Challenges, and climb the leaderboards by earning Activity Points. Whether you're memorizing scripture or testing your knowledge, MyPBE takes your PBE prep to the next level! -->
                MyPBE is dedicated to supporting participants in the 
                <a href="https://nadpbe.org/" target="_blank">Pathfinder Bible Experience (PBE)</a>
                program through innovative and engaging preparation tools. Each feature of our platform 
                is designed to make Bible study interactive and enjoyable. With both free and Pro tiers,
                MyPBE aims to provide support for every PBE participant. Join us and transform your PBE preparation today!
              </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="1s">
              <a class="anchor" href="https://app.mypbe.com/register"><span>Get Started</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="1s">
        <img src="/img/brushes/about/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/about/2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// import VueJsCounter from "vue-js-counter";
export default {
  name: "AboutComponent",
  // components: { VueJsCounter },
  props: {
    dark: { type: Boolean },
  },
};
</script>
