import { createRouter, createWebHistory } from "vue-router";
import IndexView from "../views/IndexView.vue";
import PrivacyPolicy from "@/components/PrivacyPolicy.vue";
import TermsOfUse from "@/components/TermsOfUse.vue";

const routes = [
  {
    path: "/",
    name: "Index",
    component: IndexView
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy
  },
  {
    path: "/terms-of-use",
    name: "Terms of Use",
    component: TermsOfUse
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
