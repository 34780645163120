<template>
  <div class="root">
    <el-scrollbar>
      <el-card>
        <h1>Privacy Policy</h1>

        <p>This Privacy Policy describes how MyPBE ("we", "us", or "our") collects, uses, and shares information when you
          use our website, <a href="https://mypbe.com">https://mypbe.com</a> (the "Site"), and the choices you have
          associated with that information.</p>

        <h2>Information We Collect</h2>

        <p><strong>Personal Information:</strong> We collect your email
          address, a nickname (that should not be able to identify you), timezone, and the Bible chapters you have been assigned for the competition. For users under 13 years old, we
          also require parental or legal guardian consent.</p>

        <p><strong>Usage Information:</strong> We collect information about your interactions with the Site, including your
          progress answering questions based on Bible verses, activity points earned, and other engagement metrics.</p>

        <p><strong>Cookies and Log Data:</strong> Like many websites, we use cookies and similar technologies to collect
          information and improve our services. We also collect log data that includes your IP address, browser type, pages
          visited, and other system activity.</p>

        <h2>How We Use Information</h2>

        <ul>
          <li>Provide and Improve the Site: We use the information collected to operate and maintain the Site, customize
            your experience, and improve our services.</li>
          <li>Communication: We may use your email address to send you updates, newsletters, and other communications
            related to MyPBE.</li>
          <li>Compliance and Legal Obligations: We may use your information to comply with legal obligations or to protect
            the rights and safety of MyPBE and its users.</li>
        </ul>

        <h2>Information Sharing</h2>

        <ul>
          <li>We do not share personal information with third parties except as necessary to provide and improve our
            services, comply with legal obligations, or protect the rights and safety of MyPBE and its users.</li>
          <li>We may share aggregated or de-identified information for research, analytics, or other purposes.</li>
        </ul>

        <h2>Data Security</h2>

        <p>We take reasonable measures to protect your information from unauthorized access, disclosure, alteration, or
          destruction.</p>

        <h2>Your Choices</h2>

        <ul>
          <li>You can access, update, or delete your personal information by contacting us at
            <span ref="contactSpan1"></span>.</li>
          <li>You can opt out of receiving promotional communications by following the instructions in those communications
            or contacting us directly.</li>
        </ul>

        <h2>Children's Privacy</h2>

        <p>MyPBE is intended for users aged 10-18, with parental or legal guardian consent required for users under 13. We
          do not knowingly collect personal information from children under 13 without parental consent.</p>

        <h2>Changes to This Policy</h2>

        <p>We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated
          effective date.</p>

        <h2>Contact Us</h2>

        <p>If you have any questions or concerns about this Privacy Policy, please contact us at 
          <span ref="contactSpan2"></span>.</p>
      </el-card>
    </el-scrollbar>
  </div>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  mounted() {
    this.$refs.contactSpan1.innerHTML = `
      <a href='mailto:info@mypbe.com'>info@mypbe.com</a>
      `;
    this.$refs.contactSpan2.innerHTML = `
      <a href='mailto:info@mypbe.com'>info@mypbe.com</a>
      `;
  }
};
</script>
<style scoped>
.root {
  margin: 30px;
}
</style>